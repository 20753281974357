.list-group-item:hover{
    background-color: rgb(27, 171, 82); 
    color: white;
}

.accordion {
    /* --bs-accordion-bg:#bebabc;
    --bs-accordion-color:white; 
    --bs-accordion-btn-bg: #686767;
    */
    --bs-accordion-btn-bg: var(--bs-secondary);
    --bs-accordion-btn-color: white;
    --bs-accordion-active-bg: var(--bs-secondary);
    --bs-accordion-active-color: white;
    --bs-accordion-btn-focus-box-shadow: None;
    --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='white'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='white'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.accordion {
    color: white !important;
}

.container .nav-tabs button.active {
    background-color: rgb(130, 130, 130) !important;  
    color: white !important;
    font-weight: bold;
    border: 0px;
    border-bottom: 1px solid rgb(130, 130, 130);
}

.container .nav-link {
    background-color: rgb(235, 235, 235) !important;  
    color: rgb(176, 172, 172) !important;
    font-weight: bold;
}

.container .nav-item{
    border-bottom: 5px solid rgb(130, 130, 130)   
} 

.copy-payment:hover {
    color:red;
}

.tooltip-inner {
    max-width: 600px !important;
} 

@media screen and (max-width: 576px) {
    .tooltip-inner {
        max-width: 350px !important;
    } 
}