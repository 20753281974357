:root {
    --white: #fff;
    --black: #333;
    --darkblue: #1b4965;
    --lightblue: #edf2f4;
    --table-border: #dde7ea;
    --indicator: #bb1fa4;
}

@media screen and (max-width: 992px) {
    .rounded-border-left {
        border-radius: 0px 0px 0px 0x;
    }

    .rounded-border {
        border-radius: 0px 0px 0px 0px;
    }
}
  
@media screen and (min-width: 992px) {
    .rounded-border-left {
        border-radius: 7px 0px 0px 7px;
    }

    .rounded-border {
        border-radius: 7px 7px 7px 7px;
    }
}

.calc-item-heading {
    width: 10rem;
    display: inline-block;
}

.calc-button {
    width: 48%
}

/* TABLE STYLES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
table.calc-table {
  margin: 20px 0 20px;
  text-align: left;
  border-collapse: collapse;
  border: 1px solid var(--table-border);
}

table.calc-table thead th {
  color: var(--white);
  background: var(--darkblue);
  padding: 5px;
}

table.calc-table td {
  width: 100%;
  padding: 0px;
}

table.calc-table td:first-child {
  position: relative;
  padding-right: 0px;
}

table.calc-table td:first-child::before {
  display: none;
  content: "";
  position: absolute;
  right: 100%;
  top: 50%;
  transform: translateY(-50%);
  /* width: 20px;
    height: 20px;
    border-radius: 50%;
    background: var(--indicator); */
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-left: 15px solid var(--indicator);
}

table.calc-table td:nth-child(2) {
  position: relative;
}

table.calc-table tbody tr {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  counter-increment: counter;
}

table.calc-table tbody tr::before {
  content: counter(counter);
  position: absolute;
  top: 20px;
  right: 20px;
  width: 50px;
  line-height: 50px;
  text-align: center;
  border-radius: 50%;
  font-weight: bold;
  color: var(--white);
  background: var(--black);
  z-index: 1;
}

table.calc-table tbody tr:nth-of-type(even)>* {
  background: var(--lightblue);
}


/* MQ STYLES
  –––––––––––––––––––––––––––––––––––––––––––––––––– */
@media (max-width: 999px) {
  .visible-row {
    display: none;
  }

  table.calc-table td {
    padding: 10px;
    text-align: left;
  }
}

@media (min-width: 700px) {
  table.calc-table td {
    padding: 10px;
  }
}

@media (min-width: 700px) and (max-width: 999px) {
  table.calc-table tbody {
    display: flex;
    flex-wrap: wrap;
  }

  table.calc-table tbody tr {
    width: 50%;
  }

  table.calc-table tbody tr:nth-of-type(even)>* {
    background: transparent;
  }

  table.calc-table tbody tr:nth-of-type(4n)>*,
  table tbody tr:nth-of-type(4n + 1)>* {
    background: var(--lightblue);
  }
}

@media (min-width: 999px) {
  .hidden-col {
    display: none;
  }

  table.calc-table {
    border: none;
  }

  table.calc-table th,
  table.calc-table td {
    width: 20%;
    text-align: center;
  }

  table.calc-table td:first-child {
    padding-right: 0;
  }

  table.calc-table tbody tr {
    display: table-row;
  }

  table.calc-table tbody tr::before {
    display: none;
  }
}

@media (hover: hover) and (min-width: 1000px) {
  table.calc-table tbody tr:hover {
    cursor: pointer;
  }

  table.calc-table tbody tr:hover img {
    display: block;
  }

  table.calc-table tbody tr:hover td:first-child::before {
    display: block;
  }
}

@media (min-width: 1250px) {
  table.calc-table td:first-child::before {
    right: calc(100% + 5px);
  }
}
